<template>
  <el-dialog :title="isEditDialogTitle[Number(editType)-1]" :visible.sync="isAddDialog" :before-close="dialogClose" :close-on-click-modal="false" width="420px">
    <el-form ref="form" :model="form" :rules="rules" label-width="120px" size="mini">
      <el-form-item label="编号：" prop="number" v-if="form.number">
        <div class="content-text">{{form.number}}</div>
      </el-form-item>
      <el-form-item label="姓名：" prop="contactPeopleName">
        <div class="content-text">{{form.contactPeopleName}}</div>
      </el-form-item>
      <el-form-item label="使用者号码：">
        <div class="content-text">{{form.userPhone}}</div>
      </el-form-item>
      <el-form-item label="银行预留号码：" prop="contactPhone">
        <el-input style="width:80%;" placeholder="请输入" v-model="form.contactPhone" maxlength="11" v-if="editShow"></el-input>
        <div class="content-text" v-else>{{form.contactPhone}}</div>
      </el-form-item>
      <el-form-item label="证件类型" prop="idCodeType">
        <el-select v-model="form.idCodeType" style="width:80%" v-if="editShow" placeholder="选择证件类型" size="mini">
          <el-option v-for="item in actions" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
        <div class="content-text" v-else>{{actions[form.idCodeType]?actions[form.idCodeType].name:''}}</div>
      </el-form-item>
      <el-form-item label="证件号码：" prop="idCode">
        <el-input style="width:80%;" placeholder="请输入" v-model.trim="form.idCode" maxlength="18" v-if="editShow"></el-input>
        <div class="content-text" v-else>{{form.idCode}}</div>
      </el-form-item>
      <el-form-item label="银行卡号：" prop="cardNumber">
        <el-input style="width:80%;" placeholder="请输入" v-model.trim="form.cardNumber" maxlength="19" v-if="editShow" @change="cardInput"></el-input>
        <div class="content-text" v-else>{{form.cardNumber}}</div>
      </el-form-item>
      <el-form-item label="所属银行：">
        <div class="content-text">{{form.bankName}}</div>
      </el-form-item>
      <el-form-item label="金额：" prop="amount">
        <div class="content-text">{{form.amount}}</div>
      </el-form-item>
      <el-form-item label="发放状态：">
        <div style="float:left;margin:1px 25px 0px">
          <el-tag class="el-tag-style" size="mini" type="primary" v-if="form.integralWithdrawalState == 0">未发放</el-tag>
          <el-tag class="el-tag-style" size="mini" type="success" v-if="form.integralWithdrawalState == 1">已发放</el-tag>
          <el-tag class="el-tag-style" size="mini" type="warning" v-if="form.integralWithdrawalState == 2">已退回</el-tag>
        </div>
      </el-form-item>
      <el-form-item label="提交时间：">
        <div class="content-text">{{form.createTime}}</div>
      </el-form-item>
      <el-form-item label="发放时间：" v-if="form.integralWithdrawalState == 1">
        <div class="content-text">{{form.grantTime}}</div>
      </el-form-item>
      <el-form-item label="退回时间：" v-if="form.integralWithdrawalState == 2">
        <div class="content-text">{{form.returnTime}}</div>
      </el-form-item>
      <el-form-item label="退回原因：" v-if="(editType == 4 || form.integralWithdrawalState == 2)">
        <el-select v-model="selectText" v-if="editType == 4" placeholder="快捷选择" size="mini" style="width:80%">
          <el-option label="快捷选择" value="快捷选择"></el-option>
          <el-option label="账号三要素不匹配" value="账号三要素不匹配"></el-option>
          <el-option label="名字和卡号不匹配" value="名字和卡号不匹配"></el-option>
          <el-option label="账号异常" value="账号异常"></el-option>
          <el-option label="身份证和名字不匹配" value="身份证和名字不匹配"></el-option>
          <el-option label="身份证和卡号不匹配" value="身份证和卡号不匹配"></el-option>
        </el-select>
        <div class="content-text" v-else>{{form.returnReason}}</div>
      </el-form-item>
      <el-form-item label=" " v-if="editType == 4">
        <el-input type="textarea" placeholder="请输入" v-model="returnReason" maxlength="50" style="width:80%"></el-input>
      </el-form-item>
      <el-form-item label="渠道：" :required="editType == 3" v-if="!isHide">
        <el-select v-model="form.grantType" style="width:80%" placeholder="选择渠道" size="mini" v-if="editType == 3 || editType == 4">
          <el-option v-for="item in channelList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
        <div class="content-text" v-else>{{channelList[form.grantType]?channelList[form.grantType].name:''}}</div>
      </el-form-item>
      <el-form-item label="备注：" v-if="!isHide">
        <el-input type="textarea" placeholder="请输入" v-model="form.remark" maxlength="200" style="width:208px"></el-input>
      </el-form-item>
    </el-form>
    <div>
      <el-button type="primary" v-debounce="recall" v-if="editType == 4">确认退回</el-button>
      <el-button type="primary" v-debounce="grantIntegral" v-if="editType == 3">确认发放</el-button>
      <el-button type="primary" v-debounce="onSubmit" v-if="(editType == 2 || editType == 5 || editType == 1) && !isHide">保存</el-button>
      <el-button type="primary" v-debounce="onSubmitChange" v-if="editType == 5">保存并改为未发放</el-button>
      <el-button @click="dialogClose">关闭</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { getCookies } from '@/utils/utils'
import { bankCardAttribution } from '@/utils/bankCard.js'
// console.log(())
import { grantIntegral, selectById, updateIntegralWithdrawal } from '@/api/issueList'
export default {
  props: ['isAddDialog', 'editId', 'editType', 'isHide'],
  data() {
    var checkMoney = (rule, value, callback) => {
      let reg = /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/;
      if (reg.test(value)) {
        callback()
      } else {
        callback(new Error('请输入正确金额'));
      }

      setTimeout(() => {
        if (!Number.isInteger(value)) {
          callback(new Error('请输入正确金额'));
        } else {
          callback();
        }
      }, 1000);
    };
    var validatorPhone = (rule, val, callback) => {
      if (!(/^[1][3,4,5,6,7,8,9][0-9]{9}$/.test(val))) {
        callback(new Error('请输入正确手机号'));
      } else {
        callback();
      }
    };
    var validatorIdCode = (rule, val, callback) => {
      if (!(/^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/.test(val)) && this.form.idCodeType == 0) {
        callback(new Error('请输入正确证件号码'));
      } else {
        callback();
      }
    };
    return {
      form: {
        bankName: '',
        idCode: '',
        contactPhone: '',
        contactPeopleName: '',
        cardNumber: '',
        amount: '',
        userPhone: '',
        idCodeType: '',
        grantType: '',
        remark: '',
      },
      isEditDialogTitle: ['查看提现详情', '编辑提现详情', '发放确认'],
      rules: {
        编号: [
          { required: true, message: '请填写编号', trigger: 'blur' },
        ],
        contactPeopleName: [
          { required: true, message: '请填写姓名', trigger: 'blur' },
        ],
        contactPhone: [
          { required: true, message: '手机号码不可为空', trigger: 'blur' },
          { validator: validatorPhone, trigger: 'blur' }
        ],
        idCode: [
          { required: true, message: '证件号码', trigger: 'blur' },
          { validator: validatorIdCode, trigger: 'blur' }
        ],
        idCodeType: [
          { required: true, message: '请选择证件类型', trigger: 'change' },
        ],
        cardNumber: [
          { required: true, message: '银行卡号码不可为空', trigger: 'blur' },
        ],
        amount: [
          { required: true, message: '金额不可为空', trigger: 'blur' },
          { validator: checkMoney, trigger: 'blur' }
        ],
      },
      selectText: '',
      returnReason: '',
      list: [],
      total: 0,//分页总页数
      pageNum: 1,//分页页数
      pageSize: 10,//每页显示多少
      cardType: '',
      actions: [{ name: '大陆居民身份证', id: 0 }, { name: '港澳居民身份证', id: 1 }, { name: '港澳居民内地通行证', id: 2 }],
      channelList: [
        { name: '身边云', id: 0 },
        { name: '嗨客', id: 1 },
        { name: '艾薪', id: 2 },
        { name: '合摩', id: 3 },
        { name: '小豆', id: 4 },
        { name: '孔朝', id: 5 },
        { name: '鑫路源', id: 6 },
        { name: '万友引利', id: 7 },
        { name: '北斗', id: 8 },
        { name: '中禧', id: 9 },
        { name: '广西微客(外部)', id: 10 },
        { name: '广西在线文化(外部)', id: 11 },
        { name: '其他', id: 12 }
      ],
    }
  },
  watch: {
    selectText(val) {
      this.returnReason = val
    },
  },
  computed: {
    bankName() {
      if (this.editType == 1) {
        return this.form.bankName
      }
      return cardInput(Number(this.form.cardNumber))
    },
    editShow() {
      return [2, 5].indexOf(this.editType) != -1 || !this.editType
    }
  },
  created() {
    console.log(this.editType, this.editId)
    this.editType && this.editId && this.getDateil();
  },
  methods: {
    cardInput(val) {
      console.log(val)
      if (this.form.cardNumber == '') {
        this.form.bankName = '输入银行卡号后显示所属银行'
        this.cardType = 'error'
        return
      }
      fetch(`https://ccdcapi.alipay.com/validateAndCacheCardInfo.json?_input_charset=utf-8&cardNo=${this.form.cardNumber}%20&cardBinCheck=true`).then(response => {
        return response.json();
      }).then(res => {
        console.log(111, res)
        this.cardType = res;
        if (!res.validated) {
          this.cardType = 'error'
          this.form.bankName = '请换储蓄卡或者输入正确卡号'
          return
        }
        if (res.cardType === "DC") {
          this.form.bankName = bankCardAttribution(res.bank)
        } else {
          this.form.bankName = '当前交易仅支持储蓄卡'
          this.cardType = 'error'
        }
      })
    },
    grantIntegral() {
      if (!this.form.grantType && this.form.grantType !== 0) {
        this.$message.warning('请选择渠道')
        return
      }
      let data = {
        id: this.editId,
        grantType: this.form.grantType,
        remark: this.form.remark
      }
      grantIntegral(data)
        .then((response) => {
          if (response.code == 200) {
            this.form['integralWithdrawalState'] = 1;
            this.dialogClose();
          }
          this.$message({
            type: response.code == 200 ? 'success' : 'default',
            duration: 2000,
            message: response.msg || response.error_msg,
          });
        })
    },
    dialogClose() {
      this.$emit('update:isAddDialog', false)
    },
    onSubmit(type) {
      if (this.cardType == 'error') {
        this.$message.warning('请换储蓄卡或者输入正确卡号')
        return
      }
      let form = JSON.parse(JSON.stringify(this.form));
      if (type == 1) {
        form.integralWithdrawalState = 0
      }
      this.$refs['form'].validate((valid) => {
        if (valid) {
          updateIntegralWithdrawal(form)
            .then((response) => {
              if (response.code == 200) {
                this.dialogClose();
              } else {
                this.$message.error({
                  title: '错误',
                  message: response.msg || response.error_msg,
                  duration: 1500
                });
              }
            })

        } else {
          this.$message.error({
            title: '错误',
            message: '请检查重试',
            duration: 1500
          });
          return false;
        }
      })
    },
    onSubmitChange() {
      this.$confirm('确认保存并修改为未发放状态吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.onSubmit(1)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    recall() {
      let form = JSON.parse(JSON.stringify(this.form));

      updateIntegralWithdrawal({ ...form, returnReason: this.returnReason, integralWithdrawalState: 2 })
        .then((response) => {
          if (response.code == 200) {
            this.dialogClose();
          }
          this.$message({
            type: response.code == 200 ? 'success' : 'default',
            duration: 2000,
            message: response.msg || response.error_msg,
          });
        })
    },
    getDateil() {
      selectById({ id: this.editId })
        .then((response) => {
          if (response.code == 200) {
            this.form = response.data;
          } else {
            this.$message.error({
              title: '错误',
              message: response.msg,
              duration: 1500
            });
          }

        })
    },
  }
}
</script>
<style scoped>
.el-form-item {
  margin-bottom: 16px;
}
.list-box {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
  position: relative;
  display: inline-block;
  width: 631px;

  max-height: 600px;
  margin: 0px auto 20px;
  overflow: hidden;
  overflow-y: auto;
  border: 1px solid #b4b4b4;
}

.content-text {
  font-weight: bold;
  text-align: left;
  font-size: 16px;
  padding-left: 30px;
}
</style>